<template src="./App.html" />

<script>
import AppNav from "./components/AppNav";
import LoginForm from "./components/LoginForm";

export default {
    name: "App",
    components: {
        AppNav,
        LoginForm
    }
};
</script>

<style src="./App.css"></style>
