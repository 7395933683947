import Axios from "axios";
import Store from "@/store";
import { Messages, Routes, Constants } from "./sessionmanager.json";

export function logout() {
    Axios.get(Routes.Logout).then(() => {
        Store.commit(Constants.Logout);
    });
}
export function login(username, password) {
    return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append(Constants.Username, username);
        bodyFormData.append(Constants.Password, password);

        Axios.post(Routes.Login, bodyFormData)
            .then(() => {
                Store.commit(Constants.Login, username);
                resolve();
            })
            .catch((error) => {
                if (
                    error.response.status == 401 ||
                    error.response.status == 400
                ) {
                    reject(Messages.InvalidUser);
                } else {
                    reject(Messages.Error);
                }
            });
    });
}

export function loggedIn() {
    Axios.get(Routes.LoggedIn)
        .then((response) => {
            // 200 = user session exists, 204 = no user session exists.
            if (response.status == 200) {
                // The loggedin-endpoint returns the username.
                Store.commit(Constants.Login, response.data);
            }
        })
        .catch((error) => {
            console.warn(Messages.LoggedInError + error);
        });
}
