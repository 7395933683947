<template src="./AppNav.html" />

<script>
/*
 * Navigational bar that is showed on the top of every page.
 */
import { logout } from "@/sessionmanager";

export default {
    computed: {
        // The routes are determined based on the routes inside the vue router that have a title.
        routes() {
            const routes = [];
            for (const i in this.$router.options.routes) {
                const route = this.$router.options.routes[i];

                if (!("title" in route)) continue;

                routes.push(route);
            }
            return routes;
        }
    },
    methods: {
        // Function for the logout button
        logout() {
            logout();
        }
    }
};
</script>
