import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            title: "Home",
            component: () => import("./pages/Home")
        },
        {
            path: "/modelmatrixeditor",
            name: "modelmatrixeditor",
            title: "ModelMatrix Editor",
            component: () => import("./pages/ModelMatrixEditor")
        },
        {
            path: "/processstepeditor",
            name: "processstepeditor",
            title: "ProcessStep Editor",
            component: () => import("./pages/ProcessStepEditor")
        },
        {
            path: "/financeofferoverview",
            name: "financeofferoverview",
            title: "FinanceOffer Overview",
            component: () => import("./pages/FinanceOfferOverview")
        },
        {
            path: "/financeoffereditor",
            name: "financeoffereditor",
            component: () => import("./pages/FinanceOfferEditor")
        },
        {
            path: "/dispatchprocessingmanager",
            name: "dispatchprocessingmanager",
            title: "Dispatch Processing Manager",
            component: () => import("./pages/DispatchProcessingManager")
        },
        {
            path: "/tiktokcampaignoverview",
            name: "tiktokcampaignoverview",
            title: "TikTok Campaign Overview",
            component: () => import("./pages/TikTokCampaignOverview")
        },
        {
            path: "/tiktokcampaigneditor",
            name: "tiktokcampaigneditor",
            component: () => import("./pages/TikTokCampaignEditor")
        },
        {
            path: "/facebookcampaignoverview",
            name: "facebookcampaignoverview",
            title: "Facebook Campaign Overview",
            component: () => import("./pages/FacebookCampaignOverview")
        },
        {
            path: "/facebookcampaigneditor",
            name: "facebookcampaigneditor",
            component: () => import("./pages/FacebookCampaignEditor")
        }
    ]
});
