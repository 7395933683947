<template src="./LoginForm.html" />

<script>
import { login, loggedIn } from "@/sessionmanager";

export default {
    data() {
        return {
            form: {
                username: "",
                password: ""
            },
            loginMessage: "",
            loginState: null
        };
    },
    mounted() {
        // Check if the current user has a valid session cookie.
        loggedIn();
    },
    methods: {
        // Attempts to log the user in with the username and password from the form.
        login(event) {
            event.preventDefault();
            login(this.form.username, this.form.password)
                .then(() => {
                    this.loginState = true;
                })
                .catch((errorMessage) => {
                    this.loginMessage = errorMessage;
                    this.loginState = false;
                });
        }
    }
};
</script>
