import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Axios from "axios";

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";

Vue.use(BootstrapVue);

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");
